
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .hprice h1 {
      font-size: 26px; /* Reduced size for tablets */
      
    }
  
    .hprice h2 {
      font-size: 16px; /* Reduced size for tablets */
    }
  
    .hprice {
      padding: 30px 15px; /* Reduced padding for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .hprice h1 {
      font-size: 24px; /* Even smaller font for mobile */
    }
  
    .hprice h2 {
      font-size: 14px; /* Even smaller font for mobile */
    }
  
    .hprice {
      padding: 20px 10px; /* Further reduced padding for very small screens */
    }
  }
  