.head {
  padding: 20px 0;
  color: #fff;
}

.logo h1 {
  font-size: 35px;
  font-weight: 900;
}

.logo span {
  display: block; /* Ensure the span is visible by default */
}

.head .icon {
  margin-left: 10px;
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .logo h1,
  .logo span {
    display: none; /* Hide h1 and span on mobile */
  }
  
  .social {
    display: none; /* Ensure social icons are displayed */
  }
}
