/*--------head------------*/
html {
  scroll-behavior: smooth;
}

.head {
  padding: 20px 0;
  color: #fff;
      margin_bottom: 200px;
}

.logo h1 {
  font-size: 35px;
  font-weight: 900;
}

.head .icon {
  margin-left: 10px;
}

/*--------head------------*/

/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
}

header .start a {
  color: white !important;
}

header .nav {
  display: flex !important;
}

header li {
  margin-right: 40px;
}

header ul {
  padding: 30px 20px;
}

header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}

header ul li a:hover {
  color: #7DD954;
}

.start {
  text-decoration: none; /* Ukloni podvlačenje linka */
  background-color: #7DD954;
  padding: 30px 35px; /* Adjusted for mobile */
  color: white;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}

.start a:hover {
  color: #f0f0f0; /* Opcionalno: svetlija nijansa bele za hover efekat */
}

.toggle {
  display: none;
}

/*--------header------------*/

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .start {
    display: none; /* Hide .start button on mobile */
    clip-path: none; /* Remove clip-path for mobile */
    padding: 15px; /* Adjusted padding for mobile */
  }

  header {
    margin: 0;
    background-color: #7DD954;
    position: relative;
    flex-direction: column; /* Stack items vertically */
  }

  header ul.flexSB {
    display: none; /* Hide flexSB on mobile */
  }

  header ul li {
    margin-bottom: 20px; /* Space out list items */
  }

  .toggle {
    display: block; /* Show toggle button */
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 20px; /* Adjusted position */
    top: 10px; /* Adjusted position */
  }

  .mobile-nav {
    position: absolute;
    top: 7vh; /* Adjusted for better positioning */
    left: 0;
    width: 100%;
    background-color: #7DD954;
    display: none; /* Hide mobile nav by default */
  }

  .mobile-nav.active {
    display: flex; /* Show mobile nav when active */
  }
}
