.hero {
  background-image: url("../../../../public/images/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}

.hero #heading h3 {
  color: #fff;
}

.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

/* Primary Button */
.primary-btn {
  background-color: #7DD954;
  color: #fff;
  padding: 10px 20px; /* Adjust padding */
  border: none;
  border-radius: 5px; /* Add some rounding */
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px; /* Add margin for spacing */
}

.primary-btn:hover {
  background-color: #e02727; /* Darker shade on hover */
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .margin {
    height: 60vh; /* Maintain height on smaller screens */
  }

  .hero {
    background-position: center; /* Center the background image */
    padding-top: 30%; /* Adjust padding for better visual balance */
    height: 100vh; /* Adjust height for smaller screens */

  }

  .hero #heading {
    text-align: center; /* Center the heading on mobile */
    padding: 0 20px; /* Add padding for mobile */
    width: 100%;
  }

  .hero #heading h3 {
    font-size: 24px; /* Adjust font size for mobile */
    margin: 10px 0; /* Add margin for spacing */
    width: 100%;
    text-align: center;
  }

  .hero .row {
    width: 100%;
    padding: 0 20px; /* Add padding for mobile */
    margin-left: 25px;
    text-align: center;
  }

  /* Ensure the primary button is visible on mobile */
  .primary-btn {
    display: none;
  }
}
