.coursesCard {
  padding: 50px 0;
}

.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
}

.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #7DD954;
  padding: 15px;
}

.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}

.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}

.coursesCard .rate {
  margin: 20px 0;
  color: #7DD954;
}

.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}

.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}

.coursesCard .details span {
  color: #7DD954;
  font-weight: 500;
  font-size: 14px;
}

.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}

.coursesCard h3 {
  font-weight: 500;
  color: #7DD954;
}

/*-------------online---------*/
.online {
  text-align: center;
}

.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 30px 20px;
  transition: 0.5s;
}

.online .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}

.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}

.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #7DD954;
  border-radius: 5px;
}

.online .box .show {
  opacity: 0;
}

.online .box:hover {
  background-color: #7DD954;
  border-radius: 5px;
  cursor: pointer;
}

.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.online .box:hover h1 {
  color: #fff;
}

/* Media Queries for Responsiveness */

/* Tablet and smaller screens */
@media screen and (max-width: 768px) {
  .coursesCard .items {
    padding: 20px; /* Reduced padding for smaller screens */
  }

  .coursesCard .text {
    margin-left: 0; /* Remove left margin */
    text-align: center; /* Center text */
  }

  .coursesCard .text h1 {
    font-size: 24px; /* Adjust font size */
    line-height: 36px; /* Adjust line height */
  }

  .coursesCard .rate {
    margin: 15px 0; /* Reduced margin */
  }

  .online h1 {
    font-size: 18px; /* Adjust font size */
    margin: 15px 0; /* Reduced margin */
  }

  .online span {
    font-size: 14px; /* Adjust font size */
  }
}

/* Mobile phones */
@media screen and (max-width: 480px) {
  .coursesCard .items {
    padding: 15px; /* Further reduced padding */
  }

  .coursesCard .text h1 {
    font-size: 20px; /* Further reduced font size */
  }

  .coursesCard .rate {
    font-size: 12px; /* Further reduced font size */
  }

  .online .box {
    padding: 20px 10px; /* Further reduced padding */
  }

  .online h1 {
    font-size: 16px; /* Further reduced font size */
  }

  .online span {
    font-size: 12px; /* Further reduced font size */
  }

  .coursesCard .details .dimg img {
    width: 40px; /* Smaller image size */
    height: 40px; /* Smaller image size */
  }
}
