.aboutHome {
  height: auto;
}

.aboutHome #heading {
  text-align: left;
  padding: 10;
}

.aboutHome .left img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
}

.aboutHome .right {
  padding: 40px; /* Adjusted padding for mobile */
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.aboutHome .items {
  margin-top: 30px; /* Adjusted margin for mobile */
}

.aboutHome .item {
  background-color: #fff;
  margin-top: 20px; /* Adjusted margin */
  padding: 15px; /* Adjusted padding */
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  transition: 0.5s;
}

.aboutHome .img {
  width: 150px; /* Adjusted for smaller screens */
  margin-right: 15px; /* Space between image and text */
}

.aboutHome img {
  width: 60px; /* Adjusted for smaller screens */
  height: 60px; /* Adjusted for smaller screens */
}

.aboutHome .item h2 {
  font-size: 18px; /* Reduced size for mobile */
  margin-bottom: 5px; /* Adjusted margin */
}

.aboutHome .item p {
  color: #999999;
  margin: 0; /* Remove default margin */
}

.aboutHome .item:hover {
  background-color: #7DD954;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}

.aboutHome .item:hover p {
  color: #fff;
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
  }

  .aboutHome .row {
    width: 100%;
    margin-top: 40%;
  }

  .aboutHome .right {
    padding: 20px; /* Reduced padding for mobile */
  }

  .aboutHome .item {
    margin-top: 15px; /* Further reduced margin */
    padding: 10px; /* Further reduced padding */
    flex-direction: row; /* Ensure horizontal layout */
    justify-content: flex-start; /* Align items to the start */
  }

  .aboutHome .img {
    width: 100px; /* Further reduced image size */
    margin-right: 10px; /* Space between image and text */
  }

  .aboutHome img {
    width: 50px; /* Further reduced image size */
    height: 50px; /* Further reduced image size */
  }

  .aboutHome .item h2 {
    font-size: 16px; /* Further reduced size */
  }
}
