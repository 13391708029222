.price .items {
  padding: 20px; /* Adjusted for mobile */
  text-align: left;
  display: flex; /* Ensure flexbox is applied */
  flex-direction: column; /* Stack items vertically */
  margin-bottom: 15px; /* Add space between items */
}

.price h1 {
  font-size: 30px; /* Default size for larger screens */
  margin: 10px 0; /* Adjusted margin */
  color: #7DD954;
}

.price h1 span {
  font-size: 16px; /* Default size for larger screens */
  margin-right: 5px;
  color: #000;
}

.price p {
  color: grey;
  margin: 20px 0; /* Adjusted margin */
}

/*--------------faq-------------*/
.faq .container {
  max-width: 90%; /* Increased width for mobile */
  border-radius: 5px;
  display: flex; /* Ensure flexbox is applied */
  flex-direction: column; /* Stack items vertically */
}

.faq .box {
  background-color: #fff;
  margin-bottom: 10px; /* Adjusted margin */
  padding: 15px; /* Add some padding */
}

.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px; /* Adjusted padding for mobile */
}

.faq h2 {
  font-weight: 500;
  font-size: 18px; /* Default size for larger screens */
}

.faq p {
  padding: 15px; /* Adjusted padding for mobile */
}

.faq .accordion:hover {
  background-color: #7DD954;
  color: #fff;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .price .items {
    padding: 18px; /* Adjusted padding for tablets */
  }

  .price h1 {
    font-size: 28px; /* Slightly smaller for tablets */
    text-align: left;
  }

  .price h1 span {
    font-size: 15px; /* Adjusted for tablets */
  }
}

@media (max-width: 768px) {
  .price .items {
    padding: 15px; /* Further reduced padding for mobile */
  }

  .price h1 {
    font-size: 26px; /* Smaller font for mobile */
    text-align: left;
  }

  .price h1 span {
    font-size: 14px; /* Adjusted for mobile */
  }

  .price p {
    margin: 15px 0; /* Adjusted margin */
  }

  .faq .container {
    max-width: 95%; /* Almost full width for mobile */
  }

  .faq h2 {
    font-size: 16px; /* Smaller heading size for mobile */
  }

  .faq p {
    padding: 10px; /* Adjusted padding for mobile */
  }
}

@media (max-width: 480px) {
  .price h1 {
    font-size: 24px; /* Even smaller font for very small screens */
    text-align: left;
  }

  .price h1 span {
    font-size: 13px; /* Further reduced size */
  }

  .faq h2 {
    font-size: 14px; /* Even smaller heading size */
  }

  .faq p {
    padding: 8px; /* Further adjusted padding */
  }
}
